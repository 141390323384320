import secureLocalStorage from 'react-secure-storage';
import Clear from '../assets/Icons/icon_Weather/Clear.png';
import Clouds from '../assets/Icons/icon_Weather/Clouds.png';
import Rain from '../assets/Icons/icon_Weather/rain.png';
import Thunderstorm from '../assets/Icons/icon_Weather/thunderstorm.png';

import PlantLevel0 from '../assets/Icons/plants/plant-level0.png';
import PlantLevel1 from '../assets/Icons/plants/plant-level1.png';
import PlantLevel2 from '../assets/Icons/plants/plant-level2.png';
import PlantLevel3 from '../assets/Icons/plants/plant-level3.png';
import PlantLevel4 from '../assets/Icons/plants/plant-level4.png';

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

// validate phoneNumber utilty function

export const validatePhoneInput = (phoneNumber) => {
	const newPhone = /^(254|256)([1-9]\d{8})$/;
	return newPhone.test(phoneNumber);
};


export const changeLanguage = (name) => {
	const lang = secureLocalStorage.getItem('lang');

	if (name.toLowerCase() === lang) {
		return;
	}
	secureLocalStorage.setItem('lang', name.toLowerCase());
	window.location.reload();
};

export const validateInput = (inputID) => /^[a-zA-Z0-9]{5,20}$/.test(inputID);

export const getWeatherIcons = (weatherName) => {
	switch (weatherName) {
		case 'Clear':
			return Clear;
		case 'Clouds':
			return Clouds;
		case 'Rain':
			return Rain;
		case 'thunderstorm':
			return Thunderstorm;
		default:
			break;
	}
};

export const linkToTrainingPage = (nationalId, phoneNumber) => {
	window.open(
		`${process.env.REACT_APP_TRAINING_LINK}?customId=${nationalId}&access=7BE286M3&phone=+${phoneNumber}`,
	);
};
export const getPlant = (points) => {
	if (points <= 0) return PlantLevel0;
	if (points <= 150) return PlantLevel1;
	if (points <= 300) return PlantLevel2;
	if (points <= 450) return PlantLevel3;
	if (points > 450) return PlantLevel4;
};

// funxtion for creating File object from a URL
export async function createFileFromURL(url) {
	try {
		// Fetch the file data from the URL
		const response = await fetch(url);

		if (!response.ok) {
			throw new Error('Failed to fetch file from URL');
		}

		// Extract the "Content-Type" header to determine the MIME type
		let contentType = response.headers.get('Content-Type');

		if (!contentType || !contentType.startsWith('image/')) {
			// Default to a generic image type, e.g., 'image/jpeg'
			contentType = 'image/jpeg';
		}
		// Convert the fetched data into a Blob
		const blob = await response.blob();

		// Generate a filename based on the URL
		const urlParts = typeof url === 'string' ? url.split('/') : [];
		const fileName = urlParts[urlParts.length - 1].split('?');

		// Create a File object with the Blob, generated filename, and MIME type
		const file = new File([blob], fileName[0], {
			type: contentType,
		});

		return file;
	} catch (error) {
		return null;
	}
}


export function getCurrency(companyRegionId) {
	if (companyRegionId === 1) {
		return "KES";
	} else if (companyRegionId === 3) {
		return "UGX";
	} else {
		return "KES";
	}
}