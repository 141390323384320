import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import secureLocalStorage from 'react-secure-storage';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import './helpers/i18n';
import store from './store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const tagManagerArgs = {
	gtmId: 'GTM-N2ZQHKV',
	dataLayer: {
		userId:
			secureLocalStorage.getItem('query') === null
				? 'Anonymous'
				: secureLocalStorage.getItem('query'),
		userProject: 'CustomerPortal',
	},
};

Sentry.init({
	dsn: 'https://b2931d43d2f04ec5ac64400b96b13548@o1162014.ingest.sentry.io/6483121',
	debug: (process.env.REACT_APP_ENV === 'production') ? false : true,
	environment: process.env.REACT_APP_ENV,
	release: `cp:${process.env.REACT_APP_VERSION}`,
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
});

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Provider store={store}>
				<ThemeContextProvider>
					<App />
				</ThemeContextProvider>
			</Provider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root'),
);
serviceWorkerRegistration.register();
reportWebVitals();
